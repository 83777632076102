import { Injectable, inject } from '@angular/core';
import dayjs from 'dayjs';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { TranslateService } from '@ngx-translate/core';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ExtendedSwPushService } from '@webclient/notifications/extended-sw-push.service';
import { initLangs, Lang, languageList } from '@webclient/l10n/languages';
import { Observable } from 'rxjs';
import { observe } from '@webclient/rx-utils';
import { LocalStorageKeys } from '@webclient/settings/local-storage-keys';
import { map } from 'rxjs/operators';
import { publishRef } from '@webclient/rx-share-utils';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class LangService {
    private translate = inject(TranslateService);
    httpClient = inject(HttpClient);
    private bsLocaleService = inject(BsLocaleService);
    private extendedSwPushService = inject(ExtendedSwPushService);

    public readonly localStorageLanguage$: Observable<Lang>;

    @LocalStorage(LocalStorageKeys.Language)
    localStorageLang: string;

    constructor() {
        const localStorage = inject(LocalStorageService);
        const translate = this.translate;

        initLangs(translate);

        this.localStorageLanguage$ = observe<string|null>(localStorage, LocalStorageKeys.Language).pipe(
            map(id => this.getSelectedLanguage(id)),
            publishRef()
        );
    }

    public getSelectedLanguage(lang: string|null) {
        let selectedLang = languageList.find(x => x.id === lang);

        // Choose on browserlang
        if (!selectedLang) {
            // Selected lang not found
            const cultureLang = this.translate.getBrowserCultureLang();
            if (cultureLang) {
                selectedLang = languageList.find(x => x.id === cultureLang.toLowerCase());
            }
        }

        // Choose
        if (!selectedLang) {
            // Selected lang not found
            const browserLang = this.translate.getBrowserLang();
            if (browserLang) {
                selectedLang = languageList.find(x => x.id === browserLang.toLowerCase());
            }
        }

        return selectedLang || languageList[0];
    }

    public applyLanguage(language: Lang) {
        // the lang to use, if the lang isn't available, it will use the current loader to get them.
        this.translate.use(language.id);
        this.extendedSwPushService.setLanguage(language.id);
        this.bsLocaleService.use(language.id);

        dayjs.locale(language.id);
    }
}
